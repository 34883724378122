import { Box, MenuItem, Select, Typography, useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import searchicon from "../../Assets/image/dash/Minimalistic Magnifer.png";
import viewicon from "../../Assets/image/user/223_frame.png";
import AxiosInstance from "../../Common/Api/ApiHelper";
import dummyimage from "../../Assets/image/user/9720009.jpg";
import { useNavigate } from "react-router-dom";
import { PathList } from "../../Common/Routes/Path";
import NoDataImg from "../../Common/NoData";
import Loader from "../../Common/Loader";
import debounce from "lodash.debounce";
import { apiList } from "../../Common/Api/ApiList";
import TablePagination from "@mui/material/TablePagination";
import ErrorToaster from "../../Component/ErrorToaster";
import order from "../../Assets/image/dash/Record Circle.png";
import FetchSignedUrl from "../../Component/URL_Generator";

const PAGE_SIZE_OPTIONS = [30];

const UserManagementScreen = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [status, setStatus] = useState(2);
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(PAGE_SIZE_OPTIONS[0]);
  const [totalData, setTotalData] = useState(0);

  const fetchUserData = async (
    search = "",
    page = 0,
    pageSize = PAGE_SIZE_OPTIONS[0],
    previousSearch = "",
    previousStatus = 2
  ) => {
    try {
      setLoading(true);
      let newPage = page;

      if (search !== previousSearch || status !== previousStatus) {
        console.log(search, "search");
        console.log(previousSearch, "sytat");
        newPage = 0;
        setPage(0);
      }

      let queryParams = new URLSearchParams({
        count: newPage * pageSize,
      });

      if (search.length > 0) {
        queryParams.append("search", search);
      }
      if (status !== 2) {
        queryParams.append("status", status);
      }

      let api = `${apiList.UserList}?${queryParams.toString()}`;
      const response = await AxiosInstance.get(api);

      const Rowdata = response?.data?.r?.data?.map((data, i) => ({
        rowid: i + 1,
        ...data,
      }));
      if (Rowdata?.length > 0) {
        setUser(Rowdata);
        setTotalData(response?.data?.r?.total_count);
      } else {
        if (newPage === 0) {
          setUser([]);
        }
        setTotalData(0);
      }

      setLoading(false);
      setIsLoading(false);
    } catch (error) {
      <ErrorToaster error={error} />;
      setIsLoading(false);
      setLoading(false);
    }
  };

  const handleView = (userId) => {
    navigate(`${PathList.UserDetails}/${userId}`);
  };

  const fetchUserDataDebounced = debounce(
    (search, rowsPerPage, previousSearch, previousStatus) => {
      fetchUserData(search, page, rowsPerPage, previousSearch, previousStatus);
    },
    300
  );

  const searchTermRef = useRef(searchTerm);
  const statusRef = useRef(status);

  useEffect(() => {
    fetchUserDataDebounced(
      searchTerm,

      rowsPerPage,
      searchTermRef.current,
      statusRef.current
    );

    searchTermRef.current = searchTerm;
    statusRef.current = status;

    return () => {
      fetchUserDataDebounced.cancel();
    };
  }, [searchTerm, page, rowsPerPage, status]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    // setisPrevClick(page > newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const columns = [
    {
      headerClassName: "super-app-theme--header",
      field: "rowid",
      headerName: "Sr.",
      width: 80,
      headerAlign: "center",
      renderCell: (params) => {
        return params.row.rowid + page * PAGE_SIZE_OPTIONS;
      },
    },
    {
      headerClassName: "super-app-theme--header",
      field: "profile_img",
      headerName: "Profile",
      width: 120,
      headerAlign: "center",
      renderCell: (e) => {
        const path = e?.row?.profile_img?.split(
          "https://instatrainme-api.s3.amazonaws.com/"
        )[1];
        const i =  FetchSignedUrl({ path: path });
        return (
          <Box
            sx={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {e?.row?.profile_img ? (
              <img
                src={i}
                alt=""
                style={{
                  height: "40px",
                  width: "50px",
                  borderRadius: "10px",
                  border: "1px solid #E0E0E0",
                  objectFit: "cover",
                }}
              />
            ) : (
              // <img
              //   src={
              //     e?.row?.profile_img.includes("instatrainme")
              //       ? e?.row?.profile_img
              //       : `${AppDomain}/${e?.row?.profile_img}`
              //   }
              //   alt=""
              //   style={{
              //     height: "40px",
              //     width: "50px",
              //     borderRadius: "10px",
              //     border: "1px solid #E0E0E0",
              //     objectFit: "cover",
              //   }}
              // />
              <img
                src={dummyimage}
                alt=""
                style={{
                  height: "40px",
                  width: "50px",
                  borderRadius: "10px",
                  border: "1px solid #E0E0E0",
                }}
              />
            )}
          </Box>
        );
      },
    },
    {
      headerClassName: "super-app-theme--header",
      field: "user_name",
      headerName: "Name",
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      field: "email",
      headerName: "Email",
      flex: 1,
      type: "string",
      minWidth: 250,
      headerAlign: "center",
    },

    {
      headerClassName: "super-app-theme--header",
      field: "gender",
      headerName: "Gender",
      type: "string",
      width: 150,
      headerAlign: "center",
      renderCell: (e) =>
        e?.row?.gender === 1
          ? "Male"
          : e?.row?.gender === 2
          ? "Female"
          : e?.row?.gender === 3
          ? "Other"
          : "-",
    },
    {
      headerClassName: "super-app-theme--header",
      field: "number",
      headerName: "Emergency No.",
      type: "string",
      flex: 1,
      minWidth: 230,
      headerAlign: "center",
      renderCell: (cell) => {
        let {
          row: { emr_first_name, emr_last_name, emr_number },
        } = cell;
        if (emr_first_name && emr_last_name && emr_number) {
          return (
            <span
              style={{
                background: "#3C75D4",
                color: "white",
                borderRadius: "5px",
                margin: "3px",
                padding: "5px 8px",
              }}
            >
              {emr_first_name} {emr_last_name} - {emr_number}
            </span>
          );
        } else {
          return "-";
        }
      },
    },
    {
      headerClassName: "super-app-theme--header",
      field: "status",
      headerName: "Status",
      type: "string",
      width: 200,
      headerAlign: "center",
      renderCell: (e) => {
        const { status } = e.row;
        const statusStyle = {
          fontWeight: "500",
          opacity: "0.7",
          borderRadius: "10px",
          width: "100%",
          border: "1px solid",
          height: "70%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        };

        const boxStyle = {
          width: "100px",
          margin: "0 auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        };

        if (status === 1) {
          return (
            <Box sx={boxStyle}>
              <span
                style={{
                  ...statusStyle,
                  color: "green",
                  borderColor: "green",
                }}
              >
                Active
              </span>
            </Box>
          );
        } else if (status === -1) {
          return (
            <Box sx={boxStyle}>
              <span
                style={{
                  ...statusStyle,
                  color: "grey",
                  borderColor: "grey",
                }}
              >
                Restricted
              </span>
            </Box>
          );
        } else if (status === 0 || status === -2) {
          return (
            <Box sx={boxStyle}>
              <span
                style={{
                  ...statusStyle,
                  color: "red",
                  borderColor: "red",
                }}
              >
                Deleted
              </span>
            </Box>
          );
        }
      },
    },
    {
      headerClassName: "super-app-theme--header",
      headerName: "View",
      sortable: false,
      width: 100,
      headerAlign: "center",
      renderCell: (e) => (
        <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={viewicon} alt="" onClick={() => handleView(e.row.id)} />
        </Box>
      ),
    },
  ];

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "space-between",
          gap: "1rem",
          alignItems: "end",
        }}
      >
        <Box
          sx={{
            width: "350px",
            height: "45px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          <input
            type="text"
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "10px",
              fontSize: "14px",
              boxShadow: " rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              border: "none",
              outline: "none",
              paddingLeft: "20px",
            }}
          />
          <span
            style={{
              position: "absolute",
              right: "0",
              paddingRight: "15px",
              paddingTop: "5px",
            }}
          >
            <img src={searchicon} alt="Search" />
          </span>
        </Box>
        <Box
          sx={{
            padding: "8px",
            width: { xs: "100%", md: "150px", lg: "180px", xl: "200px" },
            borderRadius: "8px",
            fontSize: "14px",
            boxShadow: "0 3px 10px 0 rgba(0, 0, 0, 0.1)", // Using a defined color
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              gap: "0.5rem",
              pb: "0.15rem",
              color: theme.palette.text.primary, // Using a generic color from theme
            }}
          >
            <img src={order} height={17} width={17} alt="" srcSet="" />
            Status
          </Box>

          <Select
            value={status}
            fullWidth
            sx={{
              backgroundColor: "#f5f5f5", // Set your desired background color
              borderRadius: "8px", // Optional: Add border radius
              ".MuiSelect-select": {
                padding: "8px 12px",
              },
            }}
            onChange={(e) => setStatus(e.target.value)}
          >
            {[
              {
                id: 2,
                status: "All",
              },

              {
                id: 1,
                status: "Active",
              },
              {
                id: -1,
                status: "Restricted",
              },
              {
                id: 0,
                status: "Deleted",
              },
            ].map((x, i) => {
              return (
                <MenuItem key={i} value={x.id} style={{ display: "block" }}>
                  {x?.status}
                </MenuItem>
              );
            })}
          </Select>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "calc(100vh - 200px)",
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          borderRadius: "5px",
          mt: 3,
          // mt: 5,
          p: 3,
        }}
      >
        <Typography sx={{ fontSize: "18px", fontWeight: "500" }}>
          User List
        </Typography>
        {/* DataGrid with filtered data */}
        {/* <Box sx={{ height: "100% !important" }}> */}
        {isLoading ? (
          <Loader />
        ) : (
          <Box
            sx={{
              height: "calc(100% - 50px)",
            }}
          >
            <DataGrid
              pagination
              loading={loading}
              rowCount={totalData}
              rows={user}
              disableColumnMenu={true}
              disableAutosize={true}
              paginationMode="server"
              hideFooter
              disableColumnFilter={true}
                getRowId={(e) => e?.rowid}
              slots={{ noRowsOverlay: NoDataImg }}
              columns={columns}
              sx={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                ".MuiDataGrid-cell": { textAlign: "center" },
                ".MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "600",
                  color: "white",
                },
                "& .super-app-theme--header": {
                  background: theme.palette.primary.Blend,
                },
              }}
              pageSize={rowsPerPage}
            />
            <TablePagination
              component="div"
              count={totalData}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              rowsPerPageOptions={PAGE_SIZE_OPTIONS}
            />
          </Box>
        )}
      </Box>
    </>
  );
};

export default UserManagementScreen;
