import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TablePagination,
  Typography,
  useTheme,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import NoDataImg from "../../Common/NoData";
import FAQActionButton from "./FAQActionButton";
import AxiosInstance from "../../Common/Api/ApiHelper";
import CustomModal from "../../Component/CustomModal";
import Loader from "../../Common/Loader";
import { apiList } from "../../Common/Api/ApiList";
import ErrorToaster from "../../Component/ErrorToaster";

const PAGE_SIZE_OPTIONS = [30];
const FAQs = () => {
  const [data, setData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState("add");
  const [selectedFAQ, setSelectedFAQ] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [totalData, setTotalData] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(PAGE_SIZE_OPTIONS[0]);

  const handleAdd = () => {
    setModalOpen(true);
    setModalMode("add");
    setSelectedFAQ(null);
    setIsButtonDisabled(true);
  };

  const handleEdit = (data) => {
    setSelectedFAQ(data);
    setModalOpen(true);
    setModalMode("edit");
    setIsButtonDisabled(true);
  };

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
    // setIsPrevClick(page > newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchUserData = async (page = 0, pageSize = PAGE_SIZE_OPTIONS[0]) => {
    try
    {
      let newPage = page * pageSize;
      const response = await AxiosInstance.get(
        `${apiList.FAQsList}?count=${newPage}`
      );
      const formatedDate = response?.data?.r?.data?.map((x, i) => ({
        ...x,
        rowid: i + 1,
      }));
      if (formatedDate?.length > 0)
      {
        setData(formatedDate);
        setTotalData(formatedDate?.total_count ?? formatedDate?.length);
      } else
      {
        if (newPage === 0)
        {
          setData([]);
        }
        setTotalData(0);
      }
      setIsLoading(false);
    } catch (error)
    {
      <ErrorToaster error={error} />;
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUserData(page);
  }, [page]);

  useEffect(() => {
    if (!modalOpen)
    {
      setIsButtonDisabled(false);
    }
  }, [modalOpen]);

  const theme = useTheme();
  const columns = [
    {
      headerClassName: "super-app-theme--header",
      field: "rowid",
      headerName: "Sr.",

      width: 80,
      headerAlign: "center",
      renderCell: (cell) => cell?.row?.rowid + page * PAGE_SIZE_OPTIONS,
    },
    {
      headerClassName: "super-app-theme--header",
      field: "question",
      headerName: "Question",
      flex: 1,
      minWidth: 250,
      headerAlign: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      field: "answer",
      headerName: "Answer",
      type: "number",
      flex: 1,
      minWidth: 250,
      headerAlign: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      headerName: "Action",
      sortable: false,
      width: 100,
      headerAlign: "center",

      renderCell: (e) => {
        return (
          <Box
            sx={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FAQActionButton
              fetchUserData={fetchUserData}
              id={e?.row?.id}
              data={e?.row}
              handleEdit={handleEdit}
              modalMode={modalMode}
              selectedFAQ={selectedFAQ}
            />
          </Box>
        );
      },
    },
  ];

  return (
    <>
      <Box
        sx={{
          width: "100%",
          // px: 3,
          // border: "solid red"
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            pb: 0,
          }}
        >
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ fontSize: "28px" }}
          >
            FAQs
          </Typography>
          <Button
            onClick={() => handleAdd()}
            disabled={isButtonDisabled}
            sx={{
              background:
                "linear-gradient(181.45deg, #7AACFF -80.86%, rgba(60, 117, 213, 0.98) 98.77%)",
              color: "white",
              textTransform: "none",
              "&:hover": {
                background:
                  "linear-gradient(181.45deg, #7AACFF -80.86%, rgba(60, 117, 213, 0.98) 98.77%)",
              },
            }}
          >
            + Add FAQ
          </Button>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: "calc(100vh - 120px)",
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            borderRadius: "5px",
            mt: 2,
            p: 3,
          }}
        >
          <Typography sx={{ fontSize: "18px", fontWeight: "500", pb: 1 }}>
            FAQs List
          </Typography>
          <Box sx={{ height: "calc(100vh - 150px)" }}>
            {isLoading ? (
              <Loader />
            ) : (
              <Box
                sx={{
                  height: "calc(100vh - 225px)",
                }}
              >
                <DataGrid
                  rows={data}
                  disableColumnMenu={true}
                  disableColumnSorting={true}
                  // disableAutosize={true}
                  hideFooter
                  disableColumnFilter={true}
                    getRowId={(e) => e?.rowid}
                  slots={{ noRowsOverlay: NoDataImg }}
                  columns={columns}
                  sx={{
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    ".MuiDataGrid-cell": { textAlign: "center" },
                    ".MuiDataGrid-columnHeaderTitle": {
                      fontWeight: "600",
                      color: "white",
                    },
                    "& .super-app-theme--header": {
                      background: theme.palette.primary.Blend,
                    },
                  }}
                />
                <TablePagination
                  component="div"
                  count={totalData}
                  page={page}
                  onPageChange={handlePageChange}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleRowsPerPageChange}
                  rowsPerPageOptions={PAGE_SIZE_OPTIONS}
                />
              </Box>
            )}
          </Box>
        </Box>

        <CustomModal
          fetchUserData={fetchUserData}
          open={modalOpen}
          handleClose={() => setModalOpen(false)}
          mode={modalMode}
          faqData={selectedFAQ}
        />
      </Box>
    </>
  );
};

export default FAQs;
